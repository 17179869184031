import { useCallback, useEffect, useRef } from "react";

import { useStore } from "react-redux";

import indexedDBManager from "@/src/stores/persist/IndexedDBManager";

/**
 * This function is used to write the persistent state to the IndexedDB while avoiding blocking the main thread.
 */
export default function useRICWriteState() {
  const store = useStore();
  const isScheduled = useRef(false);

  /**
   * Perform the write operation to IndexedDB.
   */
  const performWrite = async () => {
    const state = store.getState();

    window.requestIdleCallback(async () => {
      await indexedDBManager.writePersistedState(state);
      isScheduled.current = false; // Reset the flag after execution
    });
  };

  /**
   * Handle the WRITE_SYNC event to write the persistent state to IndexedDB.
   */
  const handleWritePersistentState = useCallback(() => {
    if (!isScheduled.current) {
      isScheduled.current = true;
      // Debounce for 500ms to avoid multiple write operations
      setTimeout(performWrite, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("WRITE_SYNC", handleWritePersistentState);

    return () => {
      document.removeEventListener("WRITE_SYNC", handleWritePersistentState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
