import type { PropsWithChildren } from "react";
import { useCallback, useEffect, useState } from "react";

import { FEATURE_CONTROL } from "@/src/constants/FeatureControl";
import ReduxProviderWrapper from "@/src/core/ReduxProviderWrapper";
import DragAndDropProvider from "@/src/pages/SpaceLandingPage/HOCs/DragAndDropProvider";
import indexedDBManager from "@/src/stores/persist/IndexedDBManager";
import useRICWriteState from "@/src/stores/persist/useRICWriteState";
import type { RootState } from "@/src/stores/rootReducer";

const RICWriteStateContainer = ({ children }: PropsWithChildren<{}>) => {
  useRICWriteState();

  return <>{children}</>;
};

const IS_INDEXED_DB_CACHE_ENABLED = FEATURE_CONTROL.INDEXED_DB_CACHE();

export default function SemblyPersistGate({ children }: PropsWithChildren<{}>) {
  const [isMounted, setIsMounted] = useState(false);
  const [preloadedState, setPreloadedState] = useState<Partial<RootState>>({});

  const handlePreloadState = useCallback(async () => {
    if (IS_INDEXED_DB_CACHE_ENABLED) {
      const [error, state] = await indexedDBManager.readPersistedState();
      if (error || !state) {
        setIsMounted(true);
        return;
      }
      setPreloadedState(state);
    }
    setIsMounted(true);
  }, []);

  useEffect(() => {
    handlePreloadState();
  }, [handlePreloadState]);

  if (!isMounted) {
    return null;
  }

  return (
    <DragAndDropProvider>
      <ReduxProviderWrapper preloadedState={preloadedState}>
        <RICWriteStateContainer>{children}</RICWriteStateContainer>
      </ReduxProviderWrapper>
    </DragAndDropProvider>
  );
}
