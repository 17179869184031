import localforage from "localforage";

export const INDEXED_DB_NAME = "sembly-redux-persistence";
export const INDEXED_DB_VERSION = 2;
export const INDEXED_DB_STORE_NAME = "sembly-redux-persistence-store";

const db = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: INDEXED_DB_NAME,
  version: INDEXED_DB_VERSION,
  storeName: INDEXED_DB_STORE_NAME,
});

/**
 * This function creates an IndexedDB storage object that can be used with redux-persist.
 * @returns An IndexedDB redux-persist compatible storage object.
 */
export const createIndexedDBStorage = () => {
  return {
    getItem: (key: string) => db.getItem(key),
    setItem<T>(key: string, value: T) {
      return db.setItem(key, value);
    },
    removeItem: (key: string) => db.removeItem(key),
  };
};
