import type { PropsWithChildren } from "react";
import React, { useRef } from "react";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { useAxiosInterceptor } from "@/src/core/AxiosFetcher";
import { MediaQueryProvider } from "@/src/hooks/useMedia";
import createStore from "@/src/stores/createStore";
import type { RootState } from "@/src/stores/rootReducer";

const ReduxProviderWrapper: React.FC<PropsWithChildren<{ preloadedState?: Partial<RootState> }>> = ({
  children,
  preloadedState,
}) => {
  const store = useRef(createStore(preloadedState));

  useAxiosInterceptor(store.current);

  return (
    <Provider store={store.current}>
      <PersistGate loading={null} persistor={persistStore(store.current)}>
        <MediaQueryProvider>
          <div>{children}</div>
        </MediaQueryProvider>
      </PersistGate>
    </Provider>
  );
};

ReduxProviderWrapper.displayName = "ReduxProviderWrapper";

export default ReduxProviderWrapper;
