import getConfig from "next/config";

export function isProdEnvironment() {
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig.APP_ENV === "prod";
}

export function isLocalEnvironment() {
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig.APP_ENV === "local";
}

export function isTestEnvironment() {
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig.APP_ENV === "test";
}
