import React, { useContext, useState } from "react";

import * as token from "@/src/components/sembly-ui/core/token";
import useIsomorphicEffect, { canUseDOM } from "@/src/hooks/useIsomorphicEffect";

interface MatchMediaState {
  matchesLargeDesktop: boolean;
  matchesMediumDesktop: boolean;
  matchesDesktop: boolean;
  matchesLargeTablet: boolean;
  matchesSmallTablet: boolean;
  matchesTablet: boolean;
  matchesMobile: boolean;
}

const INITIAL_STATE = {
  matchesLargeDesktop: true,
  matchesMediumDesktop: true,
  matchesDesktop: true,
  matchesLargeTablet: true,
  matchesSmallTablet: true,
  matchesTablet: true,
  matchesMobile: true,
};

const MediaQueryContext = React.createContext<MatchMediaState>(INITIAL_STATE);

export function generateMinWidthMediaQuery(token: string) {
  return `(min-width: ${token})`;
}

export function generateMaxWidthMediaQuery(token: string) {
  return `(max-width: ${token.replace(/[0-9]+/, match => String(Number(match) - 1))})`;
}

const largeDesktopMQ = generateMinWidthMediaQuery(token.screens.xl);
const mediumDesktopMQ = generateMinWidthMediaQuery(token.screens.lg);
const desktopMQ = generateMinWidthMediaQuery(token.screens.md);
const tabletMQ = `${generateMinWidthMediaQuery(token.screens.sm)} and ${generateMaxWidthMediaQuery(token.screens.md)}`;
const mobileMQ = generateMaxWidthMediaQuery(token.screens.sm);

const getMediaQuery = () => {
  const isWindowAvailable = typeof window !== "undefined";

  if (isWindowAvailable) {
    return {
      matchesLargeDesktop: window.matchMedia(largeDesktopMQ).matches,
      matchesMediumDesktop: window.matchMedia(mediumDesktopMQ).matches,
      matchesDesktop: window.matchMedia(desktopMQ).matches,
      matchesLargeTablet: window.matchMedia(tabletMQ).matches,
      matchesSmallTablet: window.matchMedia(tabletMQ).matches,
      matchesTablet: window.matchMedia(tabletMQ).matches,
      matchesMobile: window.matchMedia(mobileMQ).matches,
    };
  } else {
    return INITIAL_STATE;
  }
};

export const MediaQueryProvider = (props: React.PropsWithChildren<unknown>) => {
  const [media, setMedia] = useState<MatchMediaState>(INITIAL_STATE);
  const updateBreakpoints = () => {
    setMedia(getMediaQuery());
  };

  useIsomorphicEffect(() => {
    if (canUseDOM) {
      window.addEventListener("resize", updateBreakpoints);
      updateBreakpoints();
    }
    return () => {
      if (canUseDOM) {
        return window.removeEventListener("resize", updateBreakpoints);
      }
    };
  }, []);

  return <MediaQueryContext.Provider value={media}>{props.children}</MediaQueryContext.Provider>;
};

const useMedia = () => {
  return useContext(MediaQueryContext);
};

export default useMedia;
