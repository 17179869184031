import dynamic from "next/dynamic";

import AppShellSkeleton from "@/src/components/sembly-ui/components/AppShellSkeleton/AppShellSkeleton";
import SemblyPersistGate from "@/src/core/SemblyPersistGate";

const CreateReactAppEntryPoint = dynamic(() => import("./App"), { ssr: false });

function App() {
  return (
    <>
      <SemblyPersistGate>
        <CreateReactAppEntryPoint />
      </SemblyPersistGate>
      <AppShellSkeleton />
    </>
  );
}

export default App;
