import React from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// @ts-ignore
import MouseBackend from "react-dnd-mouse-backend";
import { HTML5DragTransition, MouseTransition, MultiBackend, TouchTransition } from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";

// We need MouseBackend since we want to enable mouse-wheel during dragging
// This is not possible with HTML5Backend since HTML5 disable a lot of user event during dragging
const MultiBackendOptions = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      options: { delay: 200 },
      preview: true,
      transition: HTML5DragTransition,
    },
    {
      id: "mouse",
      backend: MouseBackend,
      options: { delay: 200 },
      preview: true,
      transition: MouseTransition,
    },
    {
      id: "touch",
      backend: TouchBackend,
      preview: true,
      options: {
        delay: 200,
        scrollAngleRanges: [
          { start: 30, end: 150 },
          { start: 210, end: 330 },
        ],
      },
      transition: TouchTransition,
    },
  ],
};

function DragAndDropProvider({ children }: React.PropsWithChildren) {
  return (
    <DndProvider options={MultiBackendOptions} backend={MultiBackend}>
      {children}
    </DndProvider>
  );
}

export default DragAndDropProvider;
