import React from "react";

import clsx from "clsx";

import Logo from "@/src/components/sembly-ui/components/Logo/Logo";
import { SpaceIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";

export interface TopBarSkeletonProps {}

export const TopBarLeftUI = () => {
  return (
    <div className={clsx("-ml-16 flex h-[45px] items-center overflow-hidden md:ml-0")}>
      <div className="mr-4 md:hidden">
        <div className="relative left-0 top-0 mr-12 inline-flex w-[45px] cursor-pointer items-center justify-center bg-medium-purple-100 p-12 text-white transition duration-300 md:mr-0 md:h-64 md:w-56">
          <SpaceIcon height="24" width="24" />
        </div>
      </div>
      <Logo className="pl-8 md:pl-0" />
    </div>
  );
};

export const TopBarSkeleton = () => {
  // Somehow in some device causing glitch so hardcode via inline style
  return (
    <div className={clsx(`fixed left-0 right-0 top-0 h-[45px] w-full bg-dark-purple-100`)} style={{ height: "45px" }}>
      <div className={"m-auto flex h-full items-center justify-between px-0"}>
        <div className="flex w-full items-center justify-between pl-16 md:px-24 md:py-4">
          <TopBarLeftUI />
          <div className="md:flex md:flex-1 md:justify-end"></div>
        </div>
      </div>
    </div>
  );
};

TopBarSkeleton.displayName = "TopBarSkeleton";

export default TopBarSkeleton;
