import React from "react";

import TopBarSkeleton from "@/src/components/sembly-ui/components/AppShellSkeleton/TopBarSkeleton";

export interface AppShellSkeletonProps {}

const AppShellSkeleton: React.FC<React.PropsWithChildren<AppShellSkeletonProps>> = ({ children }) => {
  return <TopBarSkeleton />;
};

AppShellSkeleton.displayName = "AppShellSkeleton";

export default AppShellSkeleton;
