import { isLocalEnvironment, isProdEnvironment, isTestEnvironment } from "@/src/utils/NextUtil";

export const FEATURE_CONTROL = {
  BI_DIRECTIONAL: () => {
    return false;
  },
  MY_POSTS_FEED: () => {
    return false;
  },
  USER_SETTINGS: {
    OVERALL: () => {
      return true;
    },
    CHANGE_PASSWORD: () => {
      return true;
    },
    CHANGE_EMAIL: () => {
      return isLocalEnvironment();
    },
    EMAIL_NOTIFICATIONS: () => {
      return !isProdEnvironment();
    },
    EMAIL_DIGEST: () => {
      return !isProdEnvironment();
    },
    NEWSLETTER: () => {
      return !isProdEnvironment();
    },
    CONNECTION_EMAILS: () => {
      return !isProdEnvironment();
    },
    ACCOUNT_DEACTIVATION_AND_DELETION: () => {
      return isLocalEnvironment();
    },
    COOKIE_NOTICE: () => {
      return true;
    },
  },
  SPACE_SETTINGS: {
    EDIT_ORGANIZATION: () => {
      return isLocalEnvironment();
    },
  },
  PARTITION_VISIBILITY: () => {
    return isLocalEnvironment();
  },
  DRAFT: () => {
    return true;
  },
  MODERATION: {
    REPORT_USER: () => {
      return true;
    },
    REPORT_SPACE: () => {
      return !isProdEnvironment();
    },
    REPORT_POST: () => {
      return !isProdEnvironment();
    },
  },
  PREVIEW_POST: () => {
    return true;
  },
  CLOSE_ACTION: () => {
    return true;
  },
  ACTION_V2: () => {
    return true;
  },
  INDEXED_DB_CACHE: () => {
    return false;
  },
  INTERNAL: {
    TASK_PREVIEW: () => {
      return !isProdEnvironment();
    },
  },
  ENDORSE: () => {
    return false;
  },
  PRINT_REDUX_SIZE: () => {
    return isTestEnvironment() || isLocalEnvironment();
  },
  USER_TASK_NOTIFICATION: () => {
    return true;
  },
  SPACE_ACTIVITY: () => {
    return true;
  },
  SEARCH_IN_SECTION: () => {
    return !isProdEnvironment();
  },
  MY_ACTIVITY_V2: () => {
    return true;
  },
  SECTION_TAG: () => {
    return true;
  },
  CONNECT_SOCIAL_MEDIA: () => {
    return false;
  },
  SECTION_ACTIVITY: () => {
    return !isProdEnvironment();
  },
  HIDE_USER_AVATAR: () => {
    return true;
  },
  DV_HOVER_MAIN_ACTION: () => {
    return true;
  },
  RESOURCE_BROWSER: () => {
    return isLocalEnvironment();
  },
  CONCLUDE_BY_VOTING: () => {
    return !isProdEnvironment();
  },
};
