import {
  createPersistLogger,
  generateHash,
  getIndexedDBStateKey,
  mapStateToPersistedState,
  timeout,
} from "@/src/stores/persist/dbUtils";
import sliceToPersist from "@/src/stores/persist/registerPersist";
import type { RootState } from "@/src/stores/rootReducer";
import { createIndexedDBStorage } from "@/src/stores/storage/indexDBStorage";

const logger = createPersistLogger();

export interface SemblyPersistedState {
  state: Partial<RootState>;
  initialStateHash: string;
  expiryTime: number;
}

// Create IndexedDB Storage Instance for persist store
const db = createIndexedDBStorage();

/**
 * Get initial states from persist slices
 * @returns {Partial<RootState>} initial states
 */
const getInitialStates = (): Partial<RootState> => {
  const initialStates = [];
  for (const key in sliceToPersist) {
    const persistSlice = (sliceToPersist as { [key: string]: { getInitialState: () => Partial<RootState> } })[key];
    initialStates.push(persistSlice.getInitialState());
  }
  return initialStates as Partial<RootState>;
};

/**
 * Get hash of initial states from persist slices to validate schema
 * @returns {Promise<string>} hash of initial states
 */
const getInitialStatesHash = async (): Promise<string> => {
  const initialStates = getInitialStates();
  const initialStatesHash = await generateHash(initialStates);
  return initialStatesHash;
};

// Default to 7 days
const DEFAULT_TTL = 7 * 24 * 60 * 60 * 1000;
const DEFAULT_TIMEOUT = 5000;

/**
 * Write persisted state to IndexedDB with TTL and schema validation.
 * @param state store.getState() object
 * @returns {Promise<[Error | null, SemblyPersistedState | null]>} persisted state
 */
const writePersistedState = async (state: Partial<RootState>): Promise<[Error | null, SemblyPersistedState | null]> => {
  try {
    const stateToPersist = mapStateToPersistedState(state, Object.keys(sliceToPersist));
    const key = getIndexedDBStateKey();
    const data = await setItemWithTTLAndSchema(key, stateToPersist, DEFAULT_TTL);
    return [null, data];
  } catch (error) {
    logger.error("[IndexedDB] Error in writePersistedState:", error);
    return [error as Error, null];
  }
};

/**
 * Read persisted state from IndexedDB with TTL and schema validation.
 * @returns {Promise<[Error | null, SemblyPersistedState["state"] | null]>} persisted state or null if not found
 */
const readPersistedState = async (): Promise<[Error | null, SemblyPersistedState["state"] | null]> => {
  try {
    const key = getIndexedDBStateKey();
    const result = await Promise.race([
      getItemWithTTLAndSchema(key).then(data => [data, false]),
      timeout(DEFAULT_TIMEOUT), // 5 seconds timeout, adjust as needed
    ]);

    const [data, isTimeout] = result;

    if (isTimeout) {
      logger.warn(`[IndexedDB] readPersistedState: Timeout after ${DEFAULT_TIMEOUT}ms.`);
      return [null, null];
    }

    return [null, data as SemblyPersistedState["state"]];
  } catch (error) {
    logger.error("[IndexedDB] Error in readPersistedState:", error);
    return [error as Error, null];
  }
};

/**
 * Set item with TTL and schema validation.
 * @param {string} key key
 * @param {T} state state
 * @param {number} ttl time to live in milliseconds, default to 7 days
 * @returns {Promise<T>} state
 */
const setItemWithTTLAndSchema = async <T extends Partial<RootState>>(
  key: string,
  state: T,
  ttl: number = DEFAULT_TTL,
): Promise<SemblyPersistedState> => {
  // Log Performance
  const start = performance.now();

  const initialStateHash = await getInitialStatesHash();
  const expiryTime = Date.now() + ttl;

  const dataToStore: SemblyPersistedState = {
    state,
    expiryTime,
    initialStateHash,
  };

  const data = await db.setItem(key, dataToStore);

  // Log Performance
  const end = performance.now();
  const time = end - start;

  logger.log(`[IndexedDB] setItemWithTTLAndSchema took ${time}ms`);

  return data;
};

/**
 * Get item with TTL and schema validation.
 * @param {string} key key
 * @returns {Promise<SemblyPersistedState["state"] | null>} state or null if not found
 */
const getItemWithTTLAndSchema = async (key: string): Promise<SemblyPersistedState["state"] | null> => {
  // Log Performance
  const start = performance.now();
  const data = (await db.getItem(key)) as SemblyPersistedState;

  if (!data) {
    return null;
  }

  // Check TTL
  if (Date.now() > data.expiryTime) {
    logger.log(`[IndexedDB] getItemWithTTLAndSchema: Data has expired. Invalidating data.`);
    // Invalidate data if expired
    await db.removeItem(key);
    return null;
  }

  // Validate schema
  const currentHash = await getInitialStatesHash();
  if (currentHash !== data.initialStateHash) {
    logger.log(`[IndexedDB] getItemWithTTLAndSchema: Schema has changed. Invalidating data.`);
    // Invalidate data if schema has changed
    await db.removeItem(key);
    return null;
  }

  // Log Performance
  const end = performance.now();
  const time = end - start;

  logger.log(`[IndexedDB] getItemWithTTLAndSchema took ${time}ms`);

  return data.state;
};

const indexedDBManager = {
  writePersistedState,
  readPersistedState,
};

export default indexedDBManager;
